import type { FunctionComponent } from "react"

import classnames from "classnames"
import Image from "next/image"

import JtLogoBlackTeasyDarkBackground from "@/modules/brand/assets/jt-logo-black-teasy-dark-background.svg"
import JtLogoColorTeasyLightBackground from "@/modules/brand/assets/jt-logo-color-teasy-light-background.svg"

import styles from "./Brand.module.css"

type JTLogoProps = {
  from: "footer" | "header"
  JTLogoClassName?: string
}

export const JTLogo: FunctionComponent<JTLogoProps> = ({ from, JTLogoClassName }) => {
  return (
    <Image
      className={classnames(JTLogoClassName, styles["app-JtLogo"], {
        [styles["app-JtLogo___footer"]]: from === "footer",
        [styles["app-JtLogo___header"]]: from === "header",
      })}
      src={from === "header" ? JtLogoColorTeasyLightBackground : JtLogoBlackTeasyDarkBackground}
      alt=""
      height={24}
      width={128}
      priority={from === "header"}
    />
  )
}
